
<template>
  <CRow>
    <CCol col="12" xl="12">
         <CCard class="cards">
        <CCardHeader>
          <CRow>
            <CCol class="styleHeader" col="6">
             {{$t('message.inventoryReports')}}
             <br/>
              <small>{{$t('message.fromDate') + ': ' + fromDate + ' - ' + $t('message.toDate') + ': ' + toDate}}</small>
              <br/>
             <span class="sm">{{$t('message.inventoryReportNote')}}</span>
            </CCol>
             <CCol class="styleHeader" col="6">
             {{$t('message.total')}} : {{total}}
            </CCol>
        </CRow>
        </CCardHeader>
        <CCardBody>
          <div class="row">
            <div class="col-md-4" v-if='admin'>
              <CFormText>
                  {{$t('message.office')}}
                </CFormText>
              <multiselect
                        class='required'
                        id='office.id'
                        v-model='selectedOffice'
                        :options='offices'
                        :searchable='true'
                        :close-on-select='true'
                        :placeholder="$t('message.select')"
                        :selectLabel="$t('message.pressSelect')"
                        label='name'
                        track-by='id'
                        required='required'
                      >
                        <span slot='noOptions'>{{$t('message.emptyList')}}</span>
                        <span slot='noResult'>{{$t('message.noResult')}} </span>
                      </multiselect>
            </div>
            <div class="col-md-3">
              <CFormText>
                  {{$t('message.fromDate')}}
                </CFormText>
                 <input
                  id="name"
                  v-model="fromDate"
                  pattern="\d{4}-\d{2}-\d{2}"
                  type="date"
                  class="filter"
                  :placeholder="$t('message.fromDate')"
                />
            </div>
            <div class="col-md-3">
              <CFormText>
                  {{$t('message.toDate')}}
                </CFormText>
                 <input
                  id="name"
                  v-model="toDate"
                  pattern="\d{4}-\d{2}-\d{2}"
                  type="date"
                  class="filter"
                  :placeholder=" $t('message.toDate')"
                />
            </div>
            <div class="col-md-2">
              <CButton square color="btn btn-primary" v-on:click="filter" class="sub">
                    <CIcon class="c-icon" name="cil-chevron-top" /> &emsp;  {{$t('message.filter')}}
                </CButton>
            </div>
          </div>
          <hr/>
          <!-- Using the VdtnetTable component -->
          <vdtnet-table
            ref="table"
            :fields="fields"
            :opts="options"

          >
          </vdtnet-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import i18n from '../../i18n'
import VdtnetTable from '../VdtnetTable.vue'

const date = new Date()
let fromDate = (date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate())
let toDate = (date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate())
let selectOffice = ''
const permissions = localStorage.permissions
const admin = localStorage.isAdmin
const ShowOtherOfficesInventory = permissions.includes('ShowOtherOfficesInventory')
let fields = {}
if (ShowOtherOfficesInventory || admin) {
  fields = {
    name: {
      label: i18n.t('message.productName'),
      sortable: true,
      searchable: true,
      defaultOrder: 'SORT_ASC '
    },
    totalPurchases: {
      label: i18n.t('message.purchasedQuantity'),
      sortable: true,
      isLocal: true,
      searchable: true,
      defaultOrder: 'SORT_ASC '
    },
    totalSales: {
      label: i18n.t('message.soldQuantity'),
      sortable: true,
      searchable: true,
      defaultOrder: 'SORT_ASC '
    },
    stock: {
      label: i18n.t('message.quantityInStock'),
      sortable: true,
      isLocal: true,
      searchable: true,
      defaultOrder: 'SORT_ASC '
    },
    stock_cost: {
      label: i18n.t('message.stockValue'),
      sortable: true,
      isLocal: true,
      searchable: true,
      defaultOrder: 'SORT_ASC '
    },
    other_offices_stock: {
      label: i18n.t('message.other_offices_stock'),
      sortable: true,
      isLocal: true,
      searchable: true,
      defaultOrder: 'SORT_ASC '
    },
    total_stock: {
      label: i18n.t('message.total_stock'),
      sortable: true,
      isLocal: true,
      searchable: true,
      defaultOrder: 'SORT_ASC '
    }
  }
} else {
  fields = {
    name: {
      label: i18n.t('message.productName'),
      sortable: true,
      searchable: true,
      defaultOrder: 'SORT_ASC '
    },
    totalPurchases: {
      label: i18n.t('message.purchasedQuantity'),
      sortable: true,
      isLocal: true,
      searchable: true,
      defaultOrder: 'SORT_ASC '
    },
    totalSales: {
      label: i18n.t('message.soldQuantity'),
      sortable: true,
      searchable: true,
      defaultOrder: 'SORT_ASC '
    },
    stock: {
      label: i18n.t('message.quantityInStock'),
      sortable: true,
      isLocal: true,
      searchable: true,
      defaultOrder: 'SORT_ASC ',
      render: this.formatData
    },
    stock_cost: {
      label: i18n.t('message.stockValue'),
      sortable: true,
      isLocal: true,
      searchable: true,
      defaultOrder: 'SORT_ASC '
    }

  }
}
export default {
  name: 'InventoryReports',
  components: { VdtnetTable },
  data () {
    return {
      fromDate: fromDate,
      toDate: toDate,
      total: '',
      offices: [],
      selectedOffice: selectOffice,
      admin: admin,
      options: {
        ajax: {
          beforeSend: function (xhr) {
            xhr.setRequestHeader(
              'Authorization',
              'Bearer ' + localStorage.token
            )
          },
          url: this.$hostUrl + 'report/inventory',
          data: function (d) {
            d.start_date = fromDate
            d.end_date = toDate
            d.payment = 1
            d.office_id = selectOffice
          },
          dataSrc: (json) => {
            this.total = json.data.original.total_stock_cost
            return json.data.original.data
          }
        },
        buttons: [
          {
            extend: 'print',
            text: this.$t('message.print'),
            titleAttr: '',
            className: 'print',
            exportOptions: {
              columns: 'th:not(:last-child)'
            }
          },
          {
            extend: 'copy',
            text: this.$t('message.copy'),
            className: 'copy',
            titleAttr: ''
          },
          {
            extend: 'excel',
            text: '',
            titleAttr: 'إكسل',
            className: 'fa fa-table btn-success',
            exportOptions: {
              columns: 'th:not(:last-child)'
            }
          }
        ],
        dom:
          "<'row'<'col-sm-6 col-md-6'f><'col-sm-6 col-md-6 added'l>>" +
          "<'row'<'col-sm-12'Btr>>" +
          "<'row'<'col-sm-12 col-md-5'p><'col-sm-12 col-md-7'i>>",
        paging: true,
        language: {
          url: this.$t('message.url')
        },
        responsive: false,
        processing: true,
        searching: true,
        searchDelay: 1500,
        destroy: true,
        ordering: true,
        lengthChange: true,
        serverSide: false,
        fixedHeader: true,
        saveState: true,
        select: {
          style: 'multi'
        }
      },
      fields: fields,
      quickSearch: '',
      details: {},
      startDate: null,
      endDate: null
    }
  },
  created () {
    this.$http
      .get(`${this.$hostUrl}offices`)
      .then((response) => {
        this.offices = response.data.data
      })
  },
  methods: {
    doLoadTable (cb) {
      this.$http.getJSON(
        this.$hostUrl + 'report/inventory',
        function (data) {
          cb(data.data)
        }
      )
    },
    filter ($event) {
      console.log($event)
      fromDate = this.fromDate
      toDate = this.toDate
      selectOffice = this.selectedOffice.id
      const table = this.$refs.table
      table.reload()
    },
    doAfterReload (data, table) {
      console.log('data reloaded')
    },
    doCreating (comp, el) {
      console.log('creating')
    },
    doCreated (comp) {
      console.log('created')
    },
    doSearch () {
      this.$refs.table.search(this.quickSearch)
    },
    doExport (type) {
      const parms = this.$refs.table.getServerParams()
      parms.export = type
    },
    formatCode (zipcode) {
      return zipcode.split('-')[0]
    }
  }
}
</script>
<style scoped>
.sm {
  font-size: 14px;
  color: red;
}
</style>
